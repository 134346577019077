<template>
  <v-dialog
    v-model="delete_dialog"
    width="640"
    content-class="card "
    :class="is_mobile ? 'overflow-y-scroll' : ''"
  >
    <v-btn
      v-if="!is_mobile"
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="text-center">
      <h2 class="mb-4">
        {{ $t("components.unsubscribeConfirmDialog.text") }}{{ title }}?
      </h2>
      <span class="description-text">
        {{
          $i18n.locale == "nl"
            ? `Voor afmeldingen of wijzigingen vragen wij u vriendelijk om dit minimaal ${mainstore.companypage.settings.cancellation_period} uur van tevoren te doen.`
            : `For cancellations or changes, we kindly ask you to do so at least ${mainstore.companypage.settings.cancellation_period} hours in advance.`
        }}
      </span>
    </div>

    <k-spacing y="4"></k-spacing>
    <span class="unsubscribe-text">
      {{ $i18n.locale == "nl" ? "Afmeld gegevens" : "Unsubscribe details" }}
    </span>
    <v-row class="unsubscribe-row pa-2 mt-4 mb-4">
      <v-col cols="6" md="2" class="py-1">
        <span>
          {{
            $t(
              "components.registerActivityDialog.dialog_stepper_title_4_l_summary_4"
            )
          }}
        </span>
      </v-col>
      <v-col cols="6" md="10" class="py-1">
        <span>
          {{
            new Date(enroll.date * 1000)
              .toLocaleDateString("nl-NL", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(/(\w{3})/, "$1.")
          }}
        </span>
      </v-col>

      <v-col cols="6" md="2" class="">
        <span>
          {{
            $t(
              "components.registerActivityDialog.dialog_stepper_title_4_l_summary_5"
            )
          }}
        </span>
      </v-col>
      <v-col cols="6" md="10" class="">
        <span>
          {{ enroll.start }} -
          {{ enroll.end }}
        </span>
      </v-col>
    </v-row>
    <div class="pa-4 mt-4 d-flex justify-center">
      <v-btn
        @click="$emit('close-dialog')"
        class="btn btn-tertiary mr-4"
        color="var(--company-primary)"
        variant="outlined"
      >
        {{ $t("components.unsubscribeConfirmDialog.delete_btn_cancel") }}
      </v-btn>
      <v-btn
        @click="deleteitem()"
        variant="flat"
        class="btn text-white"
        color="var(--company-primary)"
      >
        {{ $t("components.unsubscribeConfirmDialog.delete_btn_remove") }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { useMainStore } from "@/stores/store";

export default {
  components: {},
  computed: {
    is_mobile() {
      return window.innerWidth <= 768;
    },
  },
  props: {
    title: String,
    enroll: Object,
  },
  data: () => ({
    delete_dialog: false,
    mainstore: useMainStore(),
  }),

  methods: {
    deleteitem() {
      this.$emit("delete");
    },
  },
};
</script>
