<template>
  <router-view v-if="ready" />
  <Relocationdialog
    v-if="relocation_dialog == true && ready == true"
    :enroll="moved_enroll"
    v-model="relocation_dialog"
    @close-dialog="relocation_dialog = false"
  />
</template>

<script>
const cookies = require("js-cookie");

import api from "./services/api";
import config from "./config/index";
import { useMainStore } from "./stores/store";
import Relocationdialog from "./components/relocation_dialog.vue";

export default {
  components: { Relocationdialog },
  data: () => ({
    ready: false,
    relocation_dialog: false,
    moved_enroll: {
      content: {
        nl: {},
        eng: {},
      },
    },
  }),
  methods: {
    setCompanyPrimaryColor(color) {
      document.documentElement.style.setProperty("--company-primary", color);
    },
  },

  beforeMount() {
    var isset_language = false;
    let subdomain = location.hostname.split(".", 1);
    let config_login = config.url.login.replace("{{domain}}", subdomain[0]);

    // Check if the route is empty or is "inloggen.dfm-app.nl"
    // Check if the hostname is "staging-app.nl" or "inloggen.staging-app.nl"
    if (
      location.hostname === "staging-app.nl" ||
      location.hostname === "inloggen.staging-app.nl" ||
      location.hostname === "dfm-app.nl" ||
      location.hostname === "inloggen.dfm-app.nl"
    ) {
      this.setCompanyPrimaryColor("#E5007D");

      // If the current hostname is not "inloggen.staging-app.nl" or the path is not "/inloggen", redirect
      if (
        location.hostname == `inloggen.${config.base_domain}` &&
        !location.pathname.startsWith("/inloggen")
      ) {
        location.replace(`https://inloggen.${config.base_domain}/inloggen`);
        return;
      }
      this.ready = true;

      // If the hostname is "inloggen.staging-app.nl" and the path is "/inloggen", skip the rest of the code
      return; // Ensures the rest of the logic is skipped
    }

    if (
      !location.pathname.startsWith("/inloggen") &&
      !location.pathname.startsWith("/registreren") &&
      !location.pathname.startsWith("/welcome") &&
      !location.pathname.startsWith("/registratie-voltooien") &&
      !location.pathname.startsWith("/wachtwoord-wijzigen") &&
      !location.pathname.startsWith("/wachtwoord-vergeten") &&
      !location.pathname.startsWith("/openbare-pagina") &&
      !location.pathname.startsWith("/review") &&
      !location.pathname.startsWith("/afspraak-bevestigen")
    ) {
      if (cookies.get("fmw_refresh_token")) {
        api
          .get(`/v1/users/me/info`)
          .then((res) => {
            useMainStore().$patch({
              user: res.data,
            });
            if (res.data.language) {
              isset_language = true;
              this.$i18n.locale = res.data.language;
            }
            if (res.data.moved_enroll) {
              this.moved_enroll = res.data.moved_enroll;
              this.relocation_dialog = true;
            }
            api
              .get(`/v1/pages/${subdomain[0]}`)
              .then((res) => {
                this.setCompanyPrimaryColor(
                  res.data.success.style.primary_color
                );
                this.ready = true;
                if (!isset_language) {
                  if (res.data.success.preferred_language == "eng") {
                    this.$i18n.locale = "en";
                  } else {
                    this.$i18n.locale = "nl";
                  }
                }
                useMainStore().$patch({
                  companypage: res.data.success,
                });
              })
              .catch(() => {
                this.ready = true;

                cookies.remove("fmw_access_token", {
                  domain: config.cookiedomain,
                  path: "/",
                });
                cookies.remove("fmw_refresh_token", {
                  domain: config.cookiedomain,
                  path: "/",
                });
                cookies.remove("user", {
                  domain: config.cookiedomain,
                  path: "/",
                });
                location.replace(
                  `${config_login}/inloggen?r=${encodeURI(location.href)}`
                );
              });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              cookies.remove("fmw_access_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("fmw_refresh_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("user", {
                domain: config.cookiedomain,
                path: "/",
              });
              location.replace(
                `${config_login}/inloggen?r=${encodeURI(location.href)}`
              );
            } else {
              location.replace(
                `${config_login}/inloggen?r=${encodeURI(location.href)}`
              );
            }
          });
      } else {
        api
          .get(`/v1/pages/${subdomain[0]}`)
          .then((res) => {
            this.setCompanyPrimaryColor(res.data.success.style.primary_color);
            useMainStore().$patch({
              companypage: res.data.success,
            });
            if (!isset_language) {
              if (res.data.success.preferred_language == "eng") {
                this.$i18n.locale = "en";
              } else {
                this.$i18n.locale = "nl";
              }
            }
            this.ready = true;
            this.$router.push("/bedrijfspagina");
          })
          .catch(() => {
            this.ready = true;

            cookies.remove("fmw_access_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("fmw_refresh_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("user", {
              domain: config.cookiedomain,
              path: "/",
            });
            location.replace("https://inloggen.dfm-app.nl/inloggen");
          });
      }
    } else {
      if (
        location.hostname !== "dfm-app.nl" ||
        location.hostname !== "inloggen.dfm-app.nl"
      ) {
        console.log(location.hostname);
        api
          .get(`/v1/pages/${subdomain[0]}`)
          .then((res) => {
            this.setCompanyPrimaryColor(res.data.success.style.primary_color);
            useMainStore().$patch({
              companypage: res.data.success,
            });
            if (!isset_language) {
              if (res.data.success.preferred_language == "eng") {
                this.$i18n.locale = "en";
              } else {
                this.$i18n.locale = "nl";
              }
            }
            this.ready = true;
          })
          .catch(() => {
            this.ready = true;
            cookies.remove("fmw_access_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("fmw_refresh_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("user", {
              domain: config.cookiedomain,
              path: "/",
            });
            location.replace(`      "https://inloggen.dfm-app.nl/inloggen`);
          });
      }
    }
  },
};
</script>

<style>
.text-company-primary {
  color: var(--company-primary) !important;
}
</style>
