<template>
  <v-dialog
    v-model="relocation_dialog"
    persistent
    width="640"
    content-class="card relocation-confirm-dialog"
  >
    <div class="text-center">
      <h2 v-if="!is_mobile">
        {{
          $i18n.locale == "nl"
            ? "Let op: Activiteit verplaatst"
            : "Note: Activity rescheduled"
        }}
      </h2>
      <span v-else class="relocation-title">
        {{
          $i18n.locale == "nl"
            ? "Let op: Activiteit verplaatst"
            : "Note: Activity rescheduled"
        }}
      </span>

      <k-spacing y="4"></k-spacing>
      <span class="relocation-text" v-html="getTranslatedText"></span>
    </div>

    <k-spacing y="4"></k-spacing>
    <div
      class="d-flex align-center flex-wrap relocation-text"
      :class="is_mobile ? 'justify-center' : ''"
    >
      {{
        $i18n.locale == "nl"
          ? "Kun je niet op deze nieuwe datum en tijd? "
          : "Unsubscribe details"
      }}

      <v-btn
        @click="change_dialog = true"
        class="btn my-auto"
        variant="text"
        color="var(--company-primary)"
      >
        {{ $i18n.locale == "nl" ? "Wijzigen" : "Change" }}
      </v-btn>
      <span class="">of</span>
      <v-btn
        @click="delete_dialog = true"
        class="btn"
        variant="text"
        color="var(--company-primary)"
      >
        {{ $i18n.locale == "nl" ? "Afmelden" : "Unsubscribe" }}
      </v-btn>
    </div>
    <div v-if="!is_mobile" class="pa-4 mt-4 d-flex justify-center">
      <v-btn
        @click="accept()"
        variant="flat"
        class="btn text-white accept-btn"
        color="var(--company-primary)"
      >
        {{ $i18n.locale == "nl" ? "Accepteren" : "Accept" }}
      </v-btn>
    </div>
    <v-row v-else class="mt-4">
      <v-col cols="12">
        <v-btn
          @click="accept()"
          variant="flat"
          block
          class="btn text-white accept-btn"
          color="var(--company-primary)"
        >
          {{ $i18n.locale == "nl" ? "Accepteren" : "Accept" }}
        </v-btn>
      </v-col>
    </v-row>
    <unsubscribeconfirmdialog
      :enroll="enroll"
      v-if="enroll.enroll_id && delete_dialog"
      :title="
        $i18n.locale == 'nl'
          ? enroll.content.nl.banner.title
          : enroll.content.eng.banner.title
      "
      @delete="cancelEnroll"
      v-model="delete_dialog"
      @close-dialog="delete_dialog = false"
    />
    <change-activity-dialog
      @cancel-enroll="cancelEnroll"
      @update-enroll="updateEnroll"
      v-model="change_dialog"
      v-if="enroll.enroll_id && change_dialog"
      :enroll="enroll"
      :page="mainstore.companypage"
      @close-dialog="change_dialog = false"
    />
    <relocation-confirm-dialog
      v-if="enroll.enroll_id"
      @close-dialog="confirm_dialog = false"
      :old_start="enroll.start"
      :old_end="enroll.end"
      :old_date="enroll.date"
      :new_start="enroll.start"
      :new_end="enroll.end"
      :new_date="enroll.date"
      :image="enroll.image"
      v-model="confirm_dialog"
    />
  </v-dialog>
</template>

<script>
import Unsubscribeconfirmdialog from "@/components/unsubscribeconfirmdialog.vue";
import changeActivityDialog from "@/components/dialogs/changeActivityDialog.vue";
import { useMainStore } from "../stores/store";
import api from "../services/api";
import config from "../config";
import relocationConfirmDialog from "./relocation_confirm_dialog.vue";

export default {
  components: {
    Unsubscribeconfirmdialog,
    changeActivityDialog,
    relocationConfirmDialog,
  },
  props: {
    title: String,
    enroll: Object,
  },
  data: () => ({
    delete_dialog: false,
    change_dialog: false,
    confirm_dialog: true,
    mainstore: useMainStore(),
  }),
  computed: {
    is_mobile() {
      return window.innerWidth <= 768;
    },
    getTranslatedText() {
      if (this.$i18n.locale == "nl") {
        return `Je aanmelding voor <strong>${
          this.enroll.content.nl.banner.title
        }</strong> is verplaatst <br> naar <strong>${new Date(
          this.enroll.date * 1000
        ).toLocaleDateString("nl-NL")}</strong> om <strong>${
          this.enroll.start
        } - ${this.enroll.end}</strong>.`;
      } else {
        return "For cancellations or changes, we kindly ask you to do so at least 48 hours in advance.";
      }
    },
  },

  methods: {
    accept() {
      api
        .post(
          `${config.url.api}/v1/activities/${this.enroll.confirmation_reference}/confirmation`,
          {
            status: "accepted",
          }
        )
        .then((res) => {
          if (res.data.success) {
            // Show the success message
            this.$toast.success(
              this.$i18n.locale == "en"
                ? "Appointment succesfully confirmed"
                : "Afspraak bevestigd"
            );

            this.confirm_dialog = true;
          }
        })
        .catch(() => {});
    },
    updateEnroll(item) {
      api
        .patch(
          `${config.url.api}/v1/activities/${item.enroll_id}/enroll`,
          item.date.slot
        )
        .then((res) => {
          if (res.data.success) {
            this.$toast.success(
              this.$t("views.template.enroll_update_succes_edited")
            );
            this.getUserActivities();
          }
        })
        .catch(() => {});
    },
    cancelEnroll() {
      api
        .post(
          `${config.url.api}/v1/activities/${this.enroll.enroll_id}/unsubscribe`,
          this.enroll
        )
        .then((res) => {
          if (res.data.s == 12) {
            this.$toast.error(
              this.$t("views.template.enroll_update_cancel_text")
            );
          }
          if (res.data.success) {
            this.$toast.success(
              this.$t("views.template.enroll_update_cancel_succes")
            );
          }
          this.relocation_dialog = false;
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              this.$toast.error(
                this.$t("views.template.enroll_update_cancel_error")
              );
              break;

            default:
              this.$toast.error(
                this.$t("views.template.enroll_update_cancel_error")
              );
              break;
          }
        });
    },
  },
};
</script>
