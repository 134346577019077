<template>
  <v-dialog
    v-model="relocation_dialog"
    persistent
    width="640"
    content-class="card overflow-hidden"
  >
    <div>
      <div class="banner-confirm-thnx-img">
        <img :src="`${configdata.url.cdn}/activities/${image}`" />
      </div>
      <k-spacing y="8"></k-spacing>
      <h3 class="step-title mt-md-16 mt-sm-8">
        {{ $i18n.locale == "nl" ? "Wijziging gelukt" : "Change successful" }}
      </h3>
      <k-spacing y="4"></k-spacing>

      <p
        v-html="
          $i18n.locale == 'nl'
            ? `Je aanmelding is succesvol verplaatst van ${new Date(
                old_date * 1000
              )
                .toLocaleDateString('nl-NL', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(
                  /(\w{3})/,
                  '$1.'
                )}, ${old_start} - ${old_end} naar  ${new Date(new_date * 1000)
                .toLocaleDateString('nl-NL', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(/(\w{3})/, '$1.')}, ${new_start} - ${new_end}`
            : ''
        "
      ></p>

      <k-spacing y="8"></k-spacing>

      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn
            class="btn text-white"
            :block="is_mobile"
            color="var(--company-primary)"
            @click="$emit('close-dialog')"
          >
            {{ $i18n.locale == "nl" ? "Afsluiten" : "Close" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { useMainStore } from "../stores/store";
import config from "@/config";
export default {
  components: {},
  props: {
    title: String,
    image: String,
    new_start: String,
    new_end: String,
    new_date: String,
    old_start: String,
    old_end: String,
    old_date: String,
  },
  data: () => ({
    config: config,
    mainstore: useMainStore(),
  }),
  computed: {
    configdata() {
      return config;
    },
    is_mobile() {
      return window.innerWidth <= 768;
    },
  },

  methods: {},
};
</script>
